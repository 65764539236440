import React from 'react'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import Typography from '@material-ui/core/Typography'

import { useStaticQuery, graphql } from 'gatsby'

import { useThemeContext } from 'context/ThemeContext'
import Link from 'components/foundations/Link/Link'
import CustomImage from 'components/foundations/Image/CustomImage'
import SEO from 'components/elements/SEO/SEO'

export default function EngineeringList() {
  const EngineeringQuery = Object.values(
    useStaticQuery(graphql`
      query EngineeringLQuery {
        allSitePage(filter: { path: { regex: "/^/servizi/ingegneria/" } }) {
          nodes {
            path
          }
        }
      }
    `).allSitePage.nodes,
  )

  const productURIs = EngineeringQuery.map((paths) => Object.values(paths).toString())

  function splitName(string) {
    return string.split('/')[3]
  }

  const { isMobile } = useThemeContext()

  return (
    <>
      <SEO title="Ingegneria" keywords={['SEO0', 'SEO1', 'SEO2']} />
      <section className="consulting closer">
        <h3 className="title">Ingegneria e Antincendio</h3>
        <CustomImage filename={'engineering.jpg'} />
        <div className="consulting-grid">
          <div className="consulting-grid-list">
            <p style={{ marginBottom: '0.5rem' }}>CATEGORIE</p>
            <div className="separator" />
            <GridList cols={isMobile ? 1 : 1} cellHeight="auto" className="gridListConsulting">
              {productURIs.map((productURL) => (
                <GridListTile className="single-consulting" key={splitName(productURL)}>
                  <Link to={productURL}>
                    <Typography className="consulting-info" component="p">
                      {splitName(productURL).replace(/-/g, ' ')}
                    </Typography>
                  </Link>
                  <hr />
                </GridListTile>
              ))}
            </GridList>
          </div>
          <div className="consulting-grid-content">
            <p className="consulting-content-text">
              <strong>
                LITIGARE CON UN INGEGNERE E’ COME FAR ROTOLARE UN MAIALE NEL FANGO. DOPO UN PO’ TI RENDI CONTO CHE
                L’INGEGNERE SI STA DIVERTENDO.
              </strong>
              <br />
              Renzo Piano
              <br /> <br />
              In ciascuna azienda nascono in continuo esigenze di ingegneria con la difficoltà di trovare un partner
              tecnico che comprenda tali esigenze ed abbia il giusto mix di creatività e di tecnica per risolvere il
              problema totalmente, professionalmente, presto ed in economia.
              <br /> <br />
              Progettiamo e dirigiamo lavori per strutture ed impianti, soddisfacendo tutte le esigenze derivanti da
              problematiche di sicurezza del lavoro.
              <br /> <br />
              Progettiamo funzionalmente alla richiesta di CPI (Certificato di Prevenzione Incendi) e curiamo tutti i
              rapporti con i Vigili del fuoco, sino all’ottenimento dell’autorizzazione antincendio.
              <br /> <br />
              Progettiamo e dirigiamo i lavori per piccoli e grandi impianti in ambito industriale, produttivo, servizi,
              trasporti e sportivo.
              <br /> <br />
              Abbiamo progettato e diretto tra l’altro l’impianto di illuminazione dello Stadio Maradona di Napoli, il
              reattore chimico di SIMPE alto 51,5 mt, gli esecutivi degli impianti elettrici di 10 stazioni della Metro
              di Roma linea T7, curato i progetti antincendio per clienti nelle province di Napoli, Avellino, Caserta,
              Livorno, Firenze, Vicenza, Milano, Arezzo, Treviso, Roma, Verona, Cesena, Alessandria, Trento, Bologna,
              Como e realizzato Piani Gestione Emergenze in circa 100 strutture distribuite su tutto il territorio
              nazionale.
              <br /> <br />
              Abbiamo coordinato la progettazione e la certificazione in conformità a UNI 11068/EN 50518 di 30 centrali
              operative nelle province di Vicenza, Firenze, Milano, Torino, Belluno, Monza e Brianza, Trento, Bologna,
              Bergamo, Sassari, Alessandria.
            </p>
          </div>
        </div>
      </section>
    </>
  )
}
